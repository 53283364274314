<template>
  <nav class="payment-tabs">
    <button
      v-for="tab in tabs"
      :id="`payment-tab-${tab.value}`"
      :key="tab.value"
      :active="tab.value === selected"
      :class="tab.value === selected ? 'active' : ''"
      @click="onChange(tab.value, $event)"
    >
      <img v-if="tab.image" :src="tab.image" :alt="tab.label" />
      <span v-if="!tab.image">
        {{ tab.label }}
      </span>
    </button>
  </nav>
</template>

<script>
export default {
  inheritAttrs: false,
  model: {
    prop: "selected",
    event: "change"
  },
  props: {
    tabs: {
      type: Array,
      required: true
    },
    selected: {
      type: String,
      default: ""
    }
  },
  methods: {
    onChange(tab, value) {
      if (value) {
        this.$emit("change", tab);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.payment-tabs {
  padding: 3px;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  background: #f1f7fe;
  border-radius: 8px;

  button {
    margin: 3px;
    display: flex;
    align-items: center;
    padding: 10px 24px;
    height: 48px;
    background: transparent;
    border-radius: 4px;
    color: #4c577f;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;

    img {
      height: 28px;
    }

    &.active {
      background: #fff;
      color: #0575f6;
    }
  }
}
</style>
