import VueScrollTo from "vue-scrollto";

const header = document.querySelector(
  "header[role='banner']"
) as HTMLElement | null;

const updatedHeader = document.querySelector(
  ".main-menu"
) as HTMLElement | null;

let headerHeight: number = 0;

if (header) {
  headerHeight = header.offsetHeight;
} else if (updatedHeader) {
  headerHeight = updatedHeader.offsetHeight;
}

const options = {
  container: "body",
  easing: "ease-in",
  offset: -(headerHeight + 20),
  force: true,
  cancelable: false,
  x: false,
  y: true
};

window.addEventListener("load", () => {
  const hash = window.location.hash;

  if (hash) {
    const id = hash.replace("#", "");
    const el = document.querySelector(`[id="${id}"]`);

    if (el && options && !el.classList.contains("skip-scroll")) {
      VueScrollTo.scrollTo(el, 500, options);
    }
  }
});

const links = document.querySelectorAll("a[href^='#']:not([data-toggle])");

if (links) {
  links.forEach(link => {
    const el = link.getAttribute("href");

    link.addEventListener("click", e => {
      if (
        e.target &&
        (e.target as HTMLElement).classList.contains("skip-scroll")
      ) {
        return true;
      }

      e.preventDefault();

      if (el && el !== "#") {
        const section = document.querySelector(el);

        if (section && options) {
          VueScrollTo.scrollTo(section, 500, options);
        }
      }
    });
  });
}

const sups = document.querySelectorAll("sup");
const notes = document.querySelector("#notes");

if (sups.length > 0 && notes) {
  sups.forEach((item: HTMLElement) => {
    item.style.cursor = "pointer";
    item.addEventListener("click", (e: Event) => {
      e.stopImmediatePropagation();

      if (options) {
        VueScrollTo.scrollTo(notes, 500, options);
      }
    });
  });
}

const scrollToButton = document.querySelector(".invest-stocks__scroll-button");
const investSection = document.querySelector(".invest-exchange");

if (scrollToButton && investSection) {
  scrollToButton.addEventListener("click", (event: Event) => {
    event.stopImmediatePropagation();

    VueScrollTo.scrollTo(investSection, 500, {
      ...options,
      offset: -headerHeight + 40
    });
  });
}
