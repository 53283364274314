import Cookie from "js-cookie";

try {
  const urlParams = new URLSearchParams(window.location.search);
  const SAME_SITE_NONE_ID = ["20973"];
  const refId = urlParams.get("ref_id");
  const rafCode = urlParams.get("raf");
  const affId = urlParams.get("affid");
  const cxdCode = urlParams.get("cxd");
  const subId = urlParams.get("sub_id");

  if (refId) {
    const sameSiteNone = SAME_SITE_NONE_ID.includes(refId);

    Cookie.set("ref_id", refId, {
      expires: 90,
      sameSite: sameSiteNone ? "None" : "Lax",
      secure: sameSiteNone
    });
  }

  if (rafCode) {
    Cookie.set("raf_code", rafCode, {
      expires: 365,
      sameSite: "Lax"
    });
  }

  if (affId) {
    Cookie.set("affid", affId, {
      expires: 365,
      sameSite: "Lax"
    });
  }

  if (cxdCode) {
    Cookie.set("cxd", cxdCode, {
      expires: 365,
      sameSite: "Lax"
    });
  }

  if (subId) {
    Cookie.set("sub_id", subId, {
      expires: 90,
      sameSite: "Lax"
    });
  }
} catch (e) {
  // eslint-disable-next-line no-console
  console.error(e.message);
}
