import { AxiosInstance } from "axios";
import { ValidateEmail } from "@/api/forms";
import { timezoneName } from "@/forms/timezone";
const userAgent = navigator.userAgent || "User Agent N/A";

export type LoginCredentials = {
  email?: string;
  password: string;
  phone_number?: string;
};

export type LoginWithPhoneCredentials = {
  phone_number?: string;
  password: string;
};

export type ResendVerifyEmail = {
  email: string;
};

export type ResetPasswordByPhoneRequest = {
  phone_number: string;
  code?: string;
};

export type LoginBySocial = {
  entity?: string;
  entity_type: string;
};

export type SocialRegisterTypes = "facebook" | "google";

export type UnifiedRegisterTypes =
  | "phone_email"
  | "facebook"
  | "google"
  | "apple";

export type UnifiedRegister = {
  country_id: string;
  company_id: string;
  entity: string;
  registration_type: UnifiedRegisterTypes;
  password?: string;
  otp?: string;
};

export const createApiUas = (axios: AxiosInstance) => {
  return {
    login: (data: LoginCredentials) =>
      axios.post("/api/user/authorise", {
        ...data,
        agent: userAgent,
        timezone: timezoneName
      }),
    loginPremiumAnalytics: (data: LoginCredentials) =>
      axios.post("/api/user/premium-analytics/authorise", {
        ...data,
        agent: userAgent,
        timezone: timezoneName
      }),
    loginToPremiumAnalyticsByPhone: (data: LoginWithPhoneCredentials) =>
      axios.post("/api/user/premium-analytics/authorise/phone", {
        ...data,
        agent: userAgent,
        timezone: timezoneName
      }),
    registerPremiumAnalytics: (data: any) =>
      axios.post("/api/user/premium-analytics/register", {
        ...data,
        agent: userAgent,
        timezone: timezoneName
      }),
    loginbyPhone: (data: LoginWithPhoneCredentials) =>
      axios.post("/api/user/authorise/phone", {
        ...data,
        agent: userAgent,
        timezone: timezoneName
      }),
    unifiedRegister: (data: UnifiedRegister) =>
      axios.post("/api/user/unified", {
        ...data,
        timezone: timezoneName
      }),
    resetPassword: (data: ResendVerifyEmail) =>
      axios.post("/api/user/reset_password", data),
    resetPasswordByPhoneRequest: (data: ResetPasswordByPhoneRequest) =>
      axios.post("/api/user/reset_password/phone", data),
    resetPasswordByPhoneProceed: (data: ResetPasswordByPhoneRequest) =>
      axios.post("/api/user/reset_password/phone/proceed", data),
    resendVerifyEmail: (data: ResendVerifyEmail) =>
      axios.post("/api/user/activation_code/resend", data),
    loginBySocial: (data: LoginBySocial) =>
      axios.post("api/user/authorise/social", {
        ...data,
        agent: userAgent,
        timezone: timezoneName
      }),
    campaignSignup: (data: ValidateEmail) =>
      axios.post("/api/campaign/signup", { ...data, agent: userAgent })
  };
};
