window.addEventListener("DOMContentLoaded", () => {
  const video = document.querySelector("#am-video") as HTMLVideoElement;
  const staticImage = document.querySelector("#am-static-image") as HTMLElement;

  if (video && staticImage) {
    const initStaticImage = () => {
      video.classList.add("hide");
      staticImage.classList.remove("hide");
    };

    const promise = video.play();

    if (promise !== undefined) {
      promise.catch(() => {
        initStaticImage();
      });
    } else {
      initStaticImage();
    }
  }
});
