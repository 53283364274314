
import Tabs from "./Tabs.vue";
import Rows from "./Rows.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    Rows,
    Tabs
  }
})
export default class extends Vue {
  @Prop(Object) readonly config!: any;
  @Prop(Object) readonly trans!: any;

  selectedTab = "";
  tabs = [] as any[];
  data = {};

  created() {
    this.prepareTabs();
    this.data = this.config[this.selectedTab];
  }

  onChange(tab: string) {
    this.data = this.config[tab];
    this.selectedTab = tab;
  }

  @Watch("config")
  onConfigChange() {
    this.prepareTabs();
    this.data = this.config[this.selectedTab];
  }

  prepareTabs() {
    this.tabs = [];
    for (const name in this.config) {
      if (this.config.hasOwnProperty(name)) {
        this.tabs.push({
          value: name,
          label: this.config[name].title,
          image: this.config[name].image
        });
      }
    }
    this.selectedTab = this.tabs[0].value;
  }

  showTabs() {
    return (
      this.tabs.length > 1 ||
      (this.tabs.length === 1 && this.tabs[0].value !== "Bank")
    );
  }
}
