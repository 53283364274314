
import { Vue, Prop, Component } from "vue-property-decorator";
import { AmSelect } from "@afe/amui/src/components/Select";
import { AmButton } from "@afe/amui/src/components/Button";
import axios from "axios";
import { AmIcon } from "@afe/amui/src/components/Icon";

@Component({
  components: { AmIcon, AmSelect, AmButton }
})
export default class extends Vue {
  @Prop(Object) readonly config!: any;
  symbol1 = "AAPL";
  symbol2 = "INTC";
  investmentPeriod = 1825;
  investedMoney = 1000;
  loading = false;

  resultDate = "";
  result1 = 0;
  result2 = 0;
  result3 = 0;
  inflation = 0;

  formatter(value: number) {
    const formatter = Intl.NumberFormat("en", {
      style: "currency",
      currency: "USD",
      maximumSignificantDigits: 3
    });

    return formatter.format(value);
  }

  dateFormatter(value: string) {
    const formatter = Intl.DateTimeFormat("es", {
      year: "numeric",
      month: "long",
      day: "numeric"
    });

    return formatter.format(new Date(value));
  }

  async getCalculation() {
    this.loading = true;
    const dateOfStart = new Date(
      new Date().getTime() - this.investmentPeriod * 86400000
    ).toISOString();
    const { data } = await axios.get<{
      symbol1: {
        buy: number;
        sell: number;
      };
      symbol2: {
        buy: number;
        sell: number;
      };
      inflation: number;
      date: string;
    }>("/api/quotes/calculator", {
      params: {
        symbol1: this.symbol1,
        symbol2: this.symbol2,
        date: dateOfStart.substring(0, 10)
      }
    });

    this.loading = false;

    return data;
  }

  async handleRecalculate() {
    const result = await this.getCalculation();

    if (result) {
      const portfolio1 = this.investedMoney / result.symbol1.buy;
      const portfolio2 = this.investedMoney / result.symbol2.buy;

      this.resultDate = this.dateFormatter(result.date);
      this.inflation = result.inflation;
      this.result1 = Math.round(portfolio1 * result.symbol1.sell) || 0;
      this.result2 = Math.round(portfolio2 * result.symbol2.sell) || 0;
      this.result3 = Math.round(
        (this.investedMoney * (100 - result.inflation)) / 100
      );
    }
  }

  get resultText2() {
    return this.config.labels.result2
      .replace("{amount}", this.formatter(this.investedMoney))
      .replace("{date_of_start}", this.resultDate);
  }

  get resultText3() {
    return this.config.labels.result3.replace("{company}", "Intel Corporation");
  }

  mounted() {
    this.handleRecalculate();
  }
}
