
import { computed, defineComponent, inject, ref } from "vue";
import AdmSymbol from "@afe/admirals-dev/src/components/AdmSymbol";
import AdmTypography from "@afe/admirals-dev/src/components/AdmTypography";
import AdmAccordionSlide from "./AccordionSlide.vue";

export const isMobile =
  (window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth) < 544;

export default defineComponent({
  name: "adm-accordion-item",
  components: { AdmTypography, AdmSymbol, AdmAccordionSlide },
  props: {
    title: {
      type: String,
      required: true
    },
    expanded: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const expandedItems = inject("expandedItems", ref<string[]>([]));
    const onChangeExpanded = inject("onChangeExpanded", (id: string) => id);
    const isExpanded = computed(() => expandedItems.value.includes(props.id));

    if (props.expanded) {
      // Initial expand
      onChangeExpanded(props.id);
    }

    const onClick = (event: any) => {
      if (event.target && event.target.tagName === "A") {
        return;
      }
      onChangeExpanded(props.id);
    };

    const classes = computed(() => ({
      "adm-accordion__item": true,
      "adm-accordion__item--opened": isExpanded.value
    }));

    return { classes, isExpanded, isMobile, onClick };
  }
});
