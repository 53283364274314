
import { Vue, Prop, Component } from "vue-property-decorator";

@Component
export default class extends Vue {
  @Prop() readonly tabName!: string;
  @Prop() readonly isActive!: boolean;
  @Prop() readonly index!: number;

  onTabClick() {
    if (!this.isActive) {
      this.$emit("tabClick", this.index);
    }
  }
}
