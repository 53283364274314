
import { Component, Prop, Vue } from "vue-property-decorator";
import { QuoteCrypto } from "@/api/quotes";
import ArrowValue from "./ArrowValue.vue";

@Component({ components: { ArrowValue } })
export default class extends Vue {
  @Prop(Object) readonly collection!: Record<string, QuoteCrypto>;
  @Prop(Object) readonly translations!: Record<string, string>;
  @Prop(Array) readonly pairs!: string[];
  @Prop(String) readonly fullPathUrl!: string;
  @Prop(String) readonly tradeButton!: string;
  @Prop(Boolean) readonly abbreviation!: false;
  @Prop(Boolean) readonly showTradeButton!: false;
}
