
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { AmTypography } from "@afe/amui/src/components/Typography";
import { AmIcon } from "@afe/amui/src/components/Icon";
import Cookie from "js-cookie";
import debounce from "lodash/debounce";

@Component({
  components: { AmTypography, AmIcon }
})
export default class extends Vue {
  @Prop({ default: false }) readonly isRemovable!: boolean;

  ASIC_CLICKED = "ASIC_RISK_WARNING_CLICKED";
  ESMA_CLICKED = "ESMA_RISK_WARNING_CLICKED";

  isRemoved = !!Cookie.get(this.ASIC_CLICKED);
  isCollapsed = !!Cookie.get(this.ESMA_CLICKED);

  handleListener = debounce(this.onWindowChange, 30);

  created() {
    window.addEventListener("load", this.handleListener);
    window.addEventListener("resize", this.handleListener);
    window.addEventListener("scroll", this.handleListener);
  }

  onToggle() {
    this.isCollapsed = !this.isCollapsed;
    if (this.isCollapsed) {
      Cookie.set(this.ESMA_CLICKED, "1");
    }
  }
  onRemove() {
    this.isRemoved = true;
    Cookie.set(this.ASIC_CLICKED, "1");
  }

  onWindowChange(event: Event) {
    if (event.type === "scroll" && this.container && !this.isCollapsed) {
      const isDisplaying = this.container.clientHeight;
      if (isDisplaying) {
        this.isCollapsed = true;
        return;
      }
    }
    this.updateBody();
  }

  get classes() {
    return {
      "risk-warning--opened": !this.isCollapsed,
      "risk-warning--removable": this.isRemovable
    };
  }

  get container() {
    return this.$refs.container as Element;
  }

  @Watch("isCollapsed")
  updateOnToogle() {
    this.updateBody();
  }

  @Watch("isRemoved")
  updateOnRemove() {
    this.updateBody(true);
  }

  updateBody(force: boolean = false) {
    setTimeout(() => {
      if (!this.container) {
        return;
      }

      const body: any = document.querySelector(".signup-page");
      const newPadding = this.container ? this.container.clientHeight : 0;
      if ((force || newPadding) && body) {
        body.style.paddingTop = newPadding + "px";
      }
    }, 0);
  }
}
