
import { Vue, Prop, Component } from "vue-property-decorator";
import LanguageSwitcherItem from "./LanguageSwitcherItem.vue";
import { AmTypography } from "@afe/amui/src/components/Typography";
import AdmSymbol from "@afe/admirals-dev/src/components/AdmSymbol";

@Component({
  components: {
    LanguageSwitcherItem,
    AmTypography,
    AdmSymbol
  }
})
export default class extends Vue {
  @Prop(Array) readonly languageList!: any;
  @Prop(String) readonly mobileText!: string;
  @Prop(String) readonly selectedTitle!: string;

  isModalOpened = false;
  isFirstOpened = false;

  onToggle() {
    this.isFirstOpened = true;
    this.isModalOpened = !this.isModalOpened;
  }
  onClose() {
    this.isModalOpened = false;
  }
  get classes() {
    return {
      "language-switcher--open": this.isModalOpened
    };
  }
}
