
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class extends Vue {
  @Prop() readonly videoId!: string;

  showIframe: boolean = false;
  showPreview: boolean = true;

  runVideo(): void {
    this.showIframe = true;

    // for smooth user experience
    setTimeout(() => {
      this.showPreview = false;
    }, 400);
  }
}
