
import { Component, Prop, Vue } from "vue-property-decorator";
import { AmResponsive } from "@afe/amui/src/components/Responsive";

@Component({
  components: { AmResponsive }
})
export default class extends Vue {
  @Prop(String) readonly showMore!: string;
  @Prop(String) readonly showLess!: string;
  @Prop({ default: "mobile,tablet" }) readonly breakpointOn?: string;
  @Prop({ default: "desktop" }) readonly breakpointOff?: string;
  @Prop({ default: false }) readonly inverted?: boolean;

  open = false;

  onClick() {
    this.open = !this.open;
  }

  get classes() {
    return [this.open ? "opened" : "closed", "details-widget"];
  }
}
