window.addEventListener("DOMContentLoaded", () => {
  const introVideo = document.querySelector(
    "#am-video-intro"
  ) as HTMLVideoElement;
  const loopVideo = document.querySelector(
    "#am-video-loop"
  ) as HTMLVideoElement;
  const staticVideo = document.querySelector("#am-video-static") as HTMLElement;

  if (introVideo && loopVideo && staticVideo) {
    const initLoopVideo = () => {
      introVideo.addEventListener("ended", () => {
        loopVideo.classList.remove("hide");
        introVideo.classList.add("hide");
      });
    };

    const initStaticVideo = () => {
      introVideo.classList.add("hide");
      staticVideo.classList.remove("hide");
    };

    const promise = introVideo.play();

    if (promise !== undefined) {
      promise
        .then(() => {
          initLoopVideo();
        })
        .catch(() => {
          initStaticVideo();
        });
    } else if ("onended" in window) {
      initLoopVideo();
    } else {
      initStaticVideo();
    }
  }
});
