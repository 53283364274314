const TVScript = () => {
  const script = document.createElement("script");
  script.src = "https://s3.tradingview.com/tv.js";
  script.setAttribute("async", "true");
  document.getElementsByTagName("head")[0].appendChild(script);

  script.addEventListener("load", createWidget, false);
};

const createWidget = () => {
  const chartRoot = document.querySelectorAll(
    ".tradingview-widget-container > div:first-child"
  );

  for (let i = 0; i < chartRoot.length; i++) {
    let id = "tv_widget_" + i;
    chartRoot[i].id = id;
    // @ts-ignore
    new TradingView.widget({
      container_id: id,
      autosize: true,
      // @ts-ignore
      symbol: chartRoot[i].dataset.symbol || "AAPL",
      // @ts-ignore
      interval: chartRoot[i].dataset.interval || "D",
      timezone: "exchange",
      theme: "light",
      style: "3",
      toolbar_bg: "#f1f3f6",
      hide_top_toolbar: true,
      save_image: false,
      locale: "en"
    });
  }
};

export default TVScript;
