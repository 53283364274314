
import Cell from "./Cell.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    Cell
  }
})
export default class extends Vue {
  @Prop(Object) readonly config!: any;
  @Prop(Object) readonly trans!: any;

  sortedNotes: any[] = [];

  created() {
    this.computeNotes();
  }

  get defaultColumns() {
    return ["fees"];
  }

  @Watch("config")
  onConfigChange() {
    this.computeNotes();
  }

  computeNotes() {
    let counter = 0;
    this.sortedNotes = [];

    if (this.config.deposit && this.config.deposit.notes) {
      this.config.deposit.notes = this.addCounters(
        this.config.deposit.notes,
        counter
      );
    }
    if (this.config.withdraw && this.config.withdraw.notes) {
      this.config.withdraw.notes = this.addCounters(
        this.config.withdraw.notes,
        counter
      );
    }
  }

  addCounters(notes: any[], counter: number) {
    return notes.map((note: any[]) => {
      const noteWithCounter = this.sortedNotes.find(item => {
        return item[1] === note[1];
      });
      if (noteWithCounter) {
        note[3] = noteWithCounter[3];
      } else {
        note[3] = ++counter;
        this.sortedNotes.push(note);
      }
      return note;
    });
  }
}
