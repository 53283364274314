
import { Vue, Prop, Component } from "vue-property-decorator";
import VueScrollTo from "vue-scrollto";
import { AmIconButton } from "@afe/amui/src/components/IconButton";

@Component({
  components: { AmIconButton }
})
export default class extends Vue {
  @Prop(String) readonly target!: string;
  @Prop({ default: -150 }) readonly offset!: number;
  @Prop({ default: "arrow_forward" }) readonly icon!: string;

  onClick() {
    const options = {
      container: "body",
      easing: "ease-in",
      offset: this.offset,
      force: true,
      cancelable: false,
      x: false,
      y: true
    };
    if (this.target) {
      VueScrollTo.scrollTo(this.target, 500, options);
    }
  }
}
