
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AmTabs } from "@afe/amui/src/components/Tabs";

@Component({
  inheritAttrs: false,
  components: {
    AmTabs
  }
})
export default class extends Vue {
  @Prop(String) readonly tabsClass?: string;
  @Prop(Array) readonly tabs!: Array<{
    value: number;
    hash: string;
    label: string;
  }>;
  value = 0;
  selected = this.tabs[0].hash;
  location = location.hash;

  mounted() {
    window.addEventListener("hashchange", this.setHash, false);

    if (!location.hash) {
      location.hash = this.selected;
      return;
    }

    this.setHash();
  }

  destroyed() {
    window.removeEventListener("hashchange", this.setHash, false);
  }

  setHash() {
    const isHashFound = this.tabs.find(tab => {
      return (
        tab.hash.toLowerCase() === location.hash.replace("#", "").toLowerCase()
      );
    });

    if (isHashFound) {
      this.selected = isHashFound.hash;
      this.value = isHashFound.value;
      location.hash = isHashFound.hash;
      return;
    }

    if (!location.hash) {
      location.hash = this.selected;
    }
  }

  get classes() {
    return ["app-tabs", this.tabsClass];
  }

  @Watch("value")
  updateOnSelected() {
    this.selected = this.tabs[this.value].hash;
    location.hash = this.tabs[this.value].hash;
  }
}
