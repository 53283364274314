import { AxiosInstance } from "axios";
import { timezoneName } from "@/forms/timezone";

export type LoginCredentials = {
  email?: string;
  password: string;
  phone_number?: string;
  otp?: string;
  otp_type?: string;
  _login?: any;
  company_id?: number;
  recaptcha?: string;
  entity?: string;
  entity_type?: string;
};

export type ValidatePhone = {
  email: string;
  phone_number: string;
};

export type ValidateEmail = {
  email: string;
  phone_number?: string;
  password?: string;
  first_name?: string;
  last_name?: string;
  user_id?: string;
  ref_id?: any;
  preDefinedPayload?: any;
  _login?: any;
  _seminar?: any;
  recaptcha?: string;
  "checkbox-age"?: boolean;
  company_id?: number | string;
  campaign_id?: number;
};

export interface SignUpStep2 {
  email: string;
  t: string;
}

export type RestorePassword = {
  email: string;
};

export type TriggerEvent = {
  fields: {
    formData: any;
    callResponse: any;
  };
  events: Array<string>;
};

export type SeminarThankYou = {
  preDefinedPayload: any;
  user_id: string;
};

export type DemoAccount = {
  account_id: number;
  platform: string;
  user_id: number;
};

export type StartTradingRegister = {
  account_type: string;
  email: string;
  "checkbox-age": boolean;
  mkt_consent: boolean;
  ref_id: any;
  utm: any;
  campaign: any;
  preDefinedPayload: any;
};

export type WhiteLabel = {
  email: string;
  message: string;
  first_name: string;
  last_name: string;
  phone_number: number;
};

export type DubaiExpo = {
  email: string;
  first_name: string;
  message?: string;
};

export type EsgMessage = {
  email: string;
  name: string;
  message: string;
};

export type UserStatus = {
  email: string;
};

export type StereoTrader = {
  email: string;
  first_name: string;
  last_name: string;
  account_number?: string;
};

export type Subscribe = {
  email: string;
  pageTag: string;
  language: string;
};

export type SignUpFinexware = {
  name: string;
  email: string;
  country: string;
};

export type ResendVerifyEmail = {
  email: string;
};

export const createApiForms = (axios: AxiosInstance) => {
  return {
    validateEmail: (data: ValidateEmail) =>
      axios.post("/utils/custom-forms/signup/validate-email", data),
    getUserStatus: (data: UserStatus) =>
      axios.post("/fcm-forms/user-status", data),
    verifyPhone: (data: ValidatePhone) =>
      axios.post("/fcm-forms/verify-phone", data),
    loginStep: () => axios.get("/fcm-forms/config/login-step"),
    tyPage: () => axios.post("/utils/custom-forms/signup/thank-you-step"),
    tyPageSingle: () => axios.post("/fcm-forms/config/ty-page-single"),
    demoTyPage: (data: DemoAccount) => axios.post("/utils/forex-demo-ty", data),
    verifyEmail: () => axios.get("/fcm-forms/config/verify-email"),
    resendVerifyEmail: (data: ResendVerifyEmail) =>
      axios.post("/utils/custom-forms/email-verify/resend", data),
    resetPasswordForm: () =>
      axios.post("/fcm-forms/config/reset-password-form"),
    restorePassword: (data: RestorePassword) =>
      axios.post("/fcm-forms/restore-password", data),
    passwordRestoreConfirmation: () =>
      axios.post("/fcm-forms/config/password-restore-confirmation"),
    signUpStep2: (data: SignUpStep2) =>
      axios.post("/utils/custom-forms/signup/signup-step-2", data),
    signUpStep2_old: (data: SignUpStep2) =>
      axios.post("/fcm-forms/config/signup-step-2", data),
    signUpDemoUser: (data: SignUpStep2) =>
      axios.post("/utils/custom-forms/demo-signup", data),
    partnerSignupStep2: (data: SignUpStep2) =>
      axios.post("/utils/custom-forms/partner/step2", data),
    islamicStep2: (data: SignUpStep2) =>
      axios.post("/utils/custom-forms/islamic-account/step2", data),
    educationSignUpStep2: (data: SignUpStep2) =>
      axios.post("/utils/custom-forms/education/signup-step2", data),
    triggerEvents: (data: TriggerEvent) =>
      axios.post("/utils/trigger-events", data),
    getClientId: (data: any) => axios.post("/utils/client-id", data),
    getProfileInfo: (data: UserStatus) =>
      axios.get(`/api/profiles/email/${data.email}`),
    seminarThankYou: (data: SeminarThankYou) =>
      axios.post("/utils/custom-forms/education/fetch-thank-you", data),
    signup: (data: any) =>
      axios.post("/fcm-forms/signup", {
        ...data,
        timezone: timezoneName
      }),
    partnerSignUp: (data: any) =>
      axios.post("/utils/custom-forms/signup/partner-signup", {
        ...data,
        timezone: timezoneName
      }),
    signupDemo: (data: any) =>
      axios.post("/utils/custom-forms/forex-demo/signup", {
        ...data,
        timezone: timezoneName
      }),
    emailSignup: (data: any) =>
      axios.post("/utils/custom-forms/email/signup", {
        ...data,
        timezone: timezoneName
      }),
    signupNew: (data: any) =>
      axios.post("/utils/custom-forms/signup", {
        ...data,
        timezone: timezoneName
      }),
    tradeDaysLead: (data: any) =>
      axios.post("/utils/custom-forms/tradedays", {
        ...data,
        timezone: timezoneName
      }),
    latamExpoRegister: (data: any) =>
      axios.post("/utils/custom-forms/latam-expo", {
        ...data,
        timezone: timezoneName
      }),
    cryptoConfigStep2: (data: SignUpStep2) =>
      axios.post("/fcm-forms/config/forex-demo-form-step-2", data),
    cryptoDemoStep2: (data: any) =>
      axios.post("/fcm-forms/create-demo-account-2-step", data),
    registerLead: (data: any) =>
      axios.post("/utils/custom-forms/signup/lead-signup", {
        ...data,
        timezone: timezoneName
      }),
    activateLead: (data: any) =>
      axios.patch("/utils/custom-forms/signup/lead-activate", data),
    mt4DownloadExist: () => axios.post("/utils/mt4-download/old"),
    mt4Step2: (data: SignUpStep2) =>
      axios.post("/utils/custom-forms/metatrader-4/step2", data),
    mt4SignUp: (data: any) =>
      axios.post("/utils/custom-forms/metatrader-5/signup", {
        ...data,
        timezone: timezoneName
      }),
    mt4Download: () => axios.post("/utils/mt4-download/new"),
    mt5Download: () => axios.post("/utils/mt5-download/new"),
    premiumAnalyticsDashboard: () => axios.post("/utils/premium-analytics"),
    mtSeDownload: (data: any) => axios.post("/utils/mt4se-ty", data),
    mtSeStep2: (data: SignUpStep2) =>
      axios.post("/utils/custom-forms/metatrader-se/step2", data),
    validateEmailStartTrading: (data: ValidateEmail) =>
      axios.post("/utils/fcm-forms/trading-central/email", data),
    startTradingThankYou: (data: SeminarThankYou) =>
      axios.post("/utils/trading-central-ty", data),
    startTradingSecondStep: (data: SignUpStep2) =>
      axios.post("/utils/fcm-forms/trading-central/step2", data),
    startTradingRegister: (data: StartTradingRegister) =>
      axios.post("/utils/fcm-forms/trading-central/register", {
        ...data,
        timezone: timezoneName
      }),
    affiliateSignup: (data: any) =>
      axios.post("/utils/custom-forms/signup/affiliate-signup", {
        ...data,
        timezone: timezoneName
      }),
    affiliateStep2: (data: SignUpStep2) =>
      axios.post("/utils/custom-forms/partner-affiliate/step2"),
    registerLeadWithDemo: (data: any) =>
      axios.post("/utils/custom-forms/signup-lead-with-demo", data),
    whiteLabel: (data: WhiteLabel) =>
      axios.post("/utils/custom-forms/white-label", data),
    dubaiExpo: (data: DubaiExpo) =>
      axios.post("/utils/custom-forms/dubai-expo", data),
    esg: (data: EsgMessage) => axios.post("/utils/custom-forms/esg", data),
    downloadEbook: (data: any) => axios.post("/utils/download-ebook", data),
    marketingEmails: (data: any) =>
      axios.post("/fcm-forms/marketing-emails", data),
    countries: (data: any) =>
      axios.post("/fcm-forms/registration-countries", data),
    zthThankYou: () =>
      axios.get("/utils/custom-forms/education/zth/fetch-thank-you"),
    companies: (data: any) =>
      axios.post("/fcm-forms/registration-companies", data),
    macTrader: (data: WhiteLabel) =>
      axios.post("/utils/custom-forms/mac-trader", {
        ...data,
        timezone: timezoneName
      }),
    macTraderJwt: (data: WhiteLabel) =>
      axios.post("/utils/custom-forms/mac-trader-jwt", data),
    getArticles: (articleType: string, data: any) =>
      axios.post(`/api/articles/${articleType}`, data),
    subscriptionSalesManago: (data: Subscribe) =>
      axios.post("/api/subscribe", data),
    signUpFinexware: (data: SignUpFinexware) =>
      axios.post("/api/sign-up-finexware", data)
  };
};
