
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class extends Vue {
  @Prop(Number) readonly value!: number;

  get classes() {
    return [
      "arrow-value",
      `arrow-value-${this.value > 0 ? "positive" : "negative"}`
    ];
  }
}
