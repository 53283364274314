import axios from "axios";

const JWT_TOKEN = "JWT-TOKEN";
const CHECK_INTERVAL = 60000;
const apiInstance = axios.create({
  withCredentials: false
});

const isEmpty = (value: string | null) =>
  !value || value.toLowerCase() === "null";

export const getToken = () => {
  try {
    const token = localStorage.getItem(JWT_TOKEN);

    if (isEmpty(token)) {
      return null;
    }

    return token;
  } catch (e) {
    return null;
  }
};

const invalidateToken = () => {
  try {
    localStorage.removeItem(JWT_TOKEN);
  } catch (e) {
    return;
  }
};

[
  document.querySelector("#btn_start"),
  document.querySelector("#btn_try_now")
].forEach($el => {
  if ($el) {
    $el.addEventListener("click", () => {
      if (document.body.classList.contains("session-user")) {
        $el.setAttribute("href", CONFIG.dashboardURL);
      }
    });
  }
});

const authenticate = (logged: boolean) => {
  try {
    let trCookie = document.cookie.match(
      new RegExp("(^| )" + "tr_sign-up" + "=([^;]+)")
    );

    if (trCookie) {
      document.body.classList.add("session-cookie");
    }

    if (logged) {
      document.body.classList.remove("session-guest");
      document.body.classList.add("session-user");
    } else {
      document.body.classList.remove("session-user");
      document.body.classList.add("session-guest");
    }
  } catch (e) {
    return;
  }
};

const logout = () => {
  invalidateToken();
  authenticate(false);
};

const setPremiumAnalytics = (link: string) => {
  const linkEl = document.querySelector("#premium-analytics-link");
  if (!linkEl) {
    return;
  }
  linkEl.setAttribute("href", link);
};

const setDestroySessionHandler = () => {
  const link = document.getElementById("destroySession");
  if (link) {
    link.addEventListener("click", () => {
      logout();
    });
  }
};

const checkSession = async () => {
  const token = getToken();

  if (!token) {
    authenticate(false);
    return;
  }

  try {
    const {
      data: { url, reload }
    } = await apiInstance.post(
      "/api/user/session",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (reload) {
      location.reload();
    }

    setDestroySessionHandler();
    setPremiumAnalytics(url);
    authenticate(true);
  } catch (e) {
    logout();
  }
};

export const initSession = () => {
  const checkTimer = setInterval(checkSession, CHECK_INTERVAL);

  checkSession().catch(() => {
    clearInterval(checkTimer);
  });
};
