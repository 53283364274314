
const getHash = () => {
  const url = document.location.href.toLowerCase();
  const regexCategory = /.+\/(cfd|etf|sb|stock).?/;
  const match = url.match(regexCategory);

  return match ? match[1] : "cfd";
};

import { Vue, Prop, Component } from "vue-property-decorator";
@Component
export default class extends Vue {
  @Prop(String) readonly hash!: string;
  currentHash = getHash();
  hashes = this.hash.toLowerCase().split(",");

  mounted() {
    window.addEventListener("onpushstate", this.hashHandler, false);
  }

  destroyed() {
    window.removeEventListener("onpushstate", this.hashHandler, false);
  }

  hashHandler() {
    this.currentHash = getHash();
  }

  get visible() {
    return this.hashes.indexOf(this.currentHash) > -1;
  }
}
