import { AxiosInstance } from "axios";

export type CfdConsentType = {
  email: string;
  first_name: string;
  last_name: string;
  has_marketing_consent: boolean;
  has_cfd_consent: boolean;
  geo_country_id: number;
  cfd_consent_data: string;
  marketing_consent_data: string;
};

export const createApiTr3 = (axios: AxiosInstance) => {
  return {
    unregisteredCfdConsent: (data: CfdConsentType) =>
      axios.post("/registration/unregistered_cfd_consent/", data),
    verifyCode: (code: string) =>
      axios.get<{ email: string }>(`/activation_code/verify/${code}/`),
    verifySms: (code: string) =>
      axios.post("/auth/send_onetime_auth_sms/", {
        otp_code_id: code
      }),
    passwordResetValidate: (hash: string) =>
      axios.get(`/password/reset/${hash}/`),
    passwordReset: ({
      hash,
      password,
      password_confirm
    }: {
      hash: string;
      password: string;
      password_confirm: string;
    }) =>
      axios.post(`/password/reset/${hash}/`, {
        password,
        password_confirm
      }),
    captchaCreateToken: (
      entity: string,
      company_id: number,
      country_id: number
    ) =>
      axios.post(
        `/captcha/registration/create_token/`,
        {
          entity,
          company_id,
          country_id
        },
        {
          headers: {
            "Api-Client": "signup_web"
          }
        }
      ),
    captchaVerify: (
      captcha_token: string,
      captcha_data: {
        captcha_id: string;
        lot_number: string;
        pass_token: string;
        gen_time: string;
        captcha_output: string;
      }
    ) =>
      axios.post(
        `/captcha/geetest4/verify/`,
        {
          captcha_token,
          captcha_data
        },
        {
          headers: {
            "Api-Client": "signup_web"
          }
        }
      )
  };
};
