function loadTrustPilot() {
  const script = document.createElement("script");
  script.src =
    "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
  script.setAttribute("async", "true");
  document.getElementsByTagName("head")[0].appendChild(script);
}

const widgets = document.getElementsByClassName("trustpilot-widget");

if (widgets.length > 0) {
  // default delay
  let delay = 1000;
  const parentEl = widgets[0].parentElement;

  if (parentEl) {
    if (parentEl.classList.contains("forex-demo-trust-pilot")) {
      delay = 0;
    }

    if (parentEl.classList.contains("invest-stocks-trust-pilot")) {
      delay = 2500;
    }
  }

  setTimeout(loadTrustPilot, delay);
}
