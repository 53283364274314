
import { Vue, Component, Prop } from "vue-property-decorator";
import { AmAutocomplete } from "@afe/amui/src/components/Autocomplete";

@Component({
  components: { AmAutocomplete }
})
export default class extends Vue {
  @Prop(String) readonly label!: string;
  @Prop(String) readonly data!: string;
  selected = "";
  options = JSON.parse(this.data) as {
    value: string;
    label: string;
    link: string;
  }[];

  created() {
    this.selected = this.options[0].label;
  }

  onSelect() {
    const selected = this.options.find(
      option => option.value === this.selected
    );
    if (selected && selected.link) {
      window.location.href = selected.link;
    }
  }
}
