import throttle from "lodash/throttle";

function offset(el: any) {
  const rect = el.getBoundingClientRect();

  return {
    top: rect.top + document.body.scrollTop,
    left: rect.left + document.body.scrollLeft
  };
}

function width(el: any) {
  return parseFloat(getComputedStyle(el, null).width.replace("px", ""));
}

function height(el: any) {
  return parseFloat(getComputedStyle(el, null).height.replace("px", ""));
}

function stickySidebar() {
  const $related = document.querySelector(".article-related");
  const $cta = document.querySelector(".article-aside__nav");
  const $top5 = document.querySelector(".article-top5");

  if (!$cta || !$top5) {
    return;
  }

  const ctaOffset = offset($cta).top;

  const listener = function() {
    if (
      ctaOffset + height($cta) / 2 <=
      window.scrollY + window.innerHeight / 2
    ) {
      $cta.classList.remove("sticky-bottom");
      $cta.setAttribute("style", "width: " + width($related) + "px");
      $cta.classList.add("sticky");

      if ($related) {
        if (offset($cta).top + height($cta) > offset($related).top) {
          $cta.classList.remove("sticky");
          $cta.classList.add("sticky-bottom");
          // @ts-ignore
          $cta.style.bottom = height($related) + 20 + 145 + "px";
        }
      } else {
        if (offset($cta).top + height($cta) > offset($top5).top - 60) {
          $cta.classList.remove("sticky");
          $cta.classList.add("sticky-bottom");
        }
      }
    } else {
      $cta.classList.remove("sticky", "sticky-bottom");
      $cta.removeAttribute("style");
    }
  };

  const stickyOnScroll = throttle(listener, 60);

  window.addEventListener("scroll", stickyOnScroll);
  window.addEventListener("load", stickyOnScroll);
}

export { stickySidebar };
