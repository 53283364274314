
import { defineComponent, ref, provide, computed } from "vue";
import "./accordion.scss";

export default defineComponent({
  name: "adm-accordion",
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const expanded = ref<string[]>([]);
    const onChangeExpanded = (id: string) => {
      if (props.multiple) {
        if (expanded.value.includes(id)) {
          expanded.value = expanded.value.filter(itemId => id !== itemId);
        } else {
          expanded.value.push(id);
        }
      } else {
        if (expanded.value.includes(id)) {
          expanded.value = [];
        } else {
          expanded.value = [id];
        }
      }
    };

    const classes = computed(() => ({
      "adm-accordion": true,
      "adm-accordion--light": props.light
    }));

    provide("onChangeExpanded", onChangeExpanded);
    provide("expandedItems", expanded);

    return { classes };
  }
});
