<template>
  <div class="swiper-container">
    <slot name="parallax-bg"></slot>
    <div :class="classes.wrapperClass">
      <slot></slot>
    </div>

    <div
      class="swiper-footer"
      v-if="
        $slots['pagination'] ||
          $slots['button-next'] ||
          $slots['button-prev'] ||
          $slots['scrollbar'] ||
          $slots['extraContent']
      "
    >
      <slot name="pagination"></slot>

      <div
        class="swiper-buttons"
        v-if="$slots['button-next'] || $slots['button-prev']"
      >
        <slot name="button-prev"></slot>
        <slot name="button-next"></slot>
      </div>

      <slot name="scrollbar"></slot>
      <slot name="extraContent"></slot>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";

const DEFAULT_EVENTS = [
  "beforeDestroy",
  "slideChange",
  "slideChangeTransitionStart",
  "slideChangeTransitionEnd",
  "slideNextTransitionStart",
  "slideNextTransitionEnd",
  "slidePrevTransitionStart",
  "slidePrevTransitionEnd",
  "transitionStart",
  "transitionEnd",
  "touchStart",
  "touchMove",
  "touchMoveOpposite",
  "sliderMove",
  "touchEnd",
  "click",
  "tap",
  "doubleTap",
  "imagesReady",
  "progress",
  "reachBeginning",
  "reachEnd",
  "fromEdge",
  "setTranslate",
  "setTransition",
  "resize",
  "centeredSlides",
  "loopAdditionalSlides",
  "loop"
];

// export
export default {
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    globalOptions: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      swiper: null,
      classes: {
        wrapperClass: "swiper-wrapper"
      }
    };
  },
  ready() {
    if (!this.swiper) {
      this.mountInstance();
    }
  },
  mounted() {
    if (!this.swiper) {
      let setClassName = false;
      for (const className in this.classes) {
        if (Object.prototype.hasOwnProperty.call(this.classes, className)) {
          if (this.options[className]) {
            setClassName = true;
            this.classes[className] = this.options[className];
          }
        }
      }
      setClassName ? this.$nextTick(this.mountInstance) : this.mountInstance();
    }
  },
  activated() {
    this.update();
  },
  updated() {
    this.update();
  },
  beforeDestroy() {
    this.$nextTick(function() {
      if (this.swiper) {
        this.swiper.destroy && this.swiper.destroy();
        delete this.swiper;
      }
    });
  },
  methods: {
    update() {
      if (this.swiper) {
        this.swiper.update && this.swiper.update();
        this.swiper.navigation && this.swiper.navigation.update();
        this.swiper.pagination && this.swiper.pagination.render();
        this.swiper.pagination && this.swiper.pagination.update();
      }
    },
    mountInstance() {
      const swiperOptions = Object.assign({}, this.globalOptions, this.options);
      setTimeout(() => {
        this.swiper = new Swiper(this.$el, swiperOptions);
        this.bindEvents();
        this.$emit("ready", this.swiper);
      }, 0);
    },
    bindEvents() {
      const vm = this;
      DEFAULT_EVENTS.forEach(eventName => {
        this.swiper.on(eventName, function() {
          vm.$emit(eventName, ...arguments);
          vm.$emit(
            eventName.replace(/([A-Z])/g, "-$1").toLowerCase(),
            ...arguments
          );
        });
      });
    }
  }
};
</script>
