import sbjs from "sourcebuster";

sbjs.init({
  referrals: [
    {
      host: "bing.com",
      medium: "organic"
    }
  ]
});
