import mediumZoom from "medium-zoom";

document.querySelectorAll(".slb").forEach(img => {
  const src: any = img.getAttribute("data-src") || img.getAttribute("src");
  img.setAttribute("data-zoom-src", src);
});

mediumZoom(".slb", {
  background: "rgba(127,127,127,.8)"
});
