
import Note from "./Note.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    Note
  }
})
export default class extends Vue {
  @Prop(String) readonly id!: string;
  @Prop(String) readonly title!: string;
  @Prop(Object) readonly config!: any;

  addNoteNumber(place: string) {
    if (!this.config.notes) {
      return null;
    }
    const find = this.config.notes.find(
      (note: any[]) => note[2] === place && note[0] === this.id
    );

    if (find) {
      return find[3];
    }
    return null;
  }
}
