
import { Component, Prop, Vue } from "vue-property-decorator";
import AdmTabs from "../Tabs";

@Component({
  components: {
    AdmTabs
  }
})
export default class extends Vue {
  @Prop(String) readonly size?: string;
  @Prop(String) readonly id!: string;
  @Prop({ default: "secondary" }) readonly variant?: string;
  @Prop({ default: 0 }) readonly activeTab!: number;
  @Prop(Array) readonly tabs!: Array<{
    label: string;
    value: string;
    disabled?: boolean;
    leadingIcon?: string;
    trailingIcon?: string;
  }>;
  selected = this.tabs[this.activeTab].value;
}
