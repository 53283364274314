
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import AdmSymbol from "@afe/admirals-dev/src/components/AdmSymbol";
import debounce from "lodash/debounce";

@Component({
  components: { AdmSymbol }
})
export default class extends Vue {
  @Prop(String) readonly apiKey!: string;

  opened = false;
  widgetInstalled = false;
  hash = "";
  searchCloseButton = document.getElementById(
    "btn_menu_search_close"
  ) as HTMLButtonElement;
  searchField = document.getElementById(
    "field_menu_search"
  ) as HTMLInputElement;
  searchBoxEl = document.getElementById("menu_search") as HTMLDivElement;
  searchSubmitEl = document.getElementById(
    "menu_search_submit"
  ) as HTMLButtonElement;

  searchOverlayEl = document.getElementById(
    "menu_search_overlay"
  ) as HTMLElement;

  onClick() {
    if (!this.widgetInstalled) {
      this.installWidget();
    }

    this.opened = !this.opened;
  }

  @Watch("opened")
  updateOpened() {
    if (this.opened) {
      this.searchBoxEl.classList.add("menu-search__opened");
      this.searchOverlayEl.style.display = "block";
      document.body.style.overflow = "hidden";
      this.hash = document.location.hash;
    } else {
      const searchContainerEl = document.querySelector(
        "#menu_search_container div"
      ) as HTMLDivElement;
      if (searchContainerEl) {
        searchContainerEl.style.display = "none";
      }

      this.searchBoxEl.classList.remove("menu-search__opened");
      this.searchOverlayEl.style.display = "none";
      this.searchField.value = "";
      document.body.style.overflow = "visible";
      document.location.hash = this.hash;
    }
  }

  mounted() {
    this.searchCloseButton.addEventListener("click", () => {
      this.opened = false;
    });
    this.searchOverlayEl.addEventListener("click", () => {
      this.opened = false;
    });
    this.searchField.addEventListener(
      "input",
      debounce(() => {
        if (this.searchField.value && this.searchField.value.length > 2) {
          this.searchSubmitEl.click();
        }
      }, 500)
    );
    document.addEventListener("keydown", ({ keyCode, key }: KeyboardEvent) => {
      if ((keyCode === 27 || key === "Escape") && this.opened) {
        this.opened = false;
      }
    });
    window.addEventListener("popstate", () => {
      if (this.opened && !window.location.hash) {
        this.opened = false;
      }
    });
  }

  installWidget() {
    (function(w: any, d: any, t: any, u: any, n: any, s?: any, e?: any) {
      w["SwiftypeObject"] = n;
      w[n] =
        w[n] ||
        function() {
          (w[n].q = w[n].q || []).push(arguments);
        };
      s = d.createElement(t);
      e = d.getElementsByTagName(t)[0];
      s.async = 1;
      s.src = u;
      e.parentNode.insertBefore(s, e);
    })(
      window,
      document,
      "script",
      "//s.swiftypecdn.com/install/v2/st.js",
      "_st_menu_search"
    );
    // @ts-ignore
    _st_menu_search("install", this.apiKey, "2.0.0");

    this.widgetInstalled = true;
  }
}
