import "intersection-observer"; // polyfill
import lozad from "lozad";
import mediumZoom from "medium-zoom";
import TVScript from "@/custom/tradingViewWidget";

const resizeUrl = (url: string, resize: string) => {
  if (!url.match(/\.(?:jpg|jpeg|png)$/i)) {
    return url;
  }

  const baseProxyUrl = "https://dynamic-images.admiralmarkets.com";
  const options = {
    auto: {
      desktop: "720x",
      mobile: "375x"
    },
    thumbnail: "200x"
  };

  let screenSize = window.innerWidth;
  let width,
    format = "webp";

  switch (resize) {
    // allowed sizes defined in the nginx config !!!
    case "auto":
      width = screenSize >= 420 ? options.auto.desktop : options.auto.mobile;
      break;
    case "thumbnail":
      width = options.thumbnail;
      format = "jpeg";
      break;
    default:
      return url;
  }

  url = url.replace("https://", "");

  return `${baseProxyUrl}/${width},${format}/${url}`;
};

const observer = lozad("img[data-src]", {
  threshold: 0.1,
  load: function(el) {
    let url = el.getAttribute("data-src");
    const resize = el.getAttribute("data-resize");
    // @ts-ignore
    el.src = resize ? resizeUrl(url, resize) : url;
  }
});

const bgImageObserver = lozad("[data-background-image]", {
  threshold: 0.1,
  load: function(el) {
    let url = el.getAttribute("data-background-image");
    if (!url || url === "") {
      return;
    }
    // @ts-ignore
    el.style.backgroundImage = "url(" + url + ")";
  }
});

const pictureTagObserver = lozad("picture.lozad", {
  threshold: 0.1,
  load: function(el) {
    const img = document.createElement("img");
    const src: any = el.getAttribute("data-iesrc");
    const youtube = el.parentElement?.classList.contains("youtube-preview");
    const top_articles = el.parentElement?.classList.contains(
      "widget-top-item__img"
    );

    if (!youtube) {
      if (top_articles) {
        img.setAttribute("src", resizeUrl(src, "thumbnail"));
      } else {
        img.setAttribute("src", src);
        img.setAttribute("data-zoom-src", src);
      }
      img.setAttribute("class", el.getAttribute("data-class") || "");
    }

    img.setAttribute("style", el.getAttribute("data-style") || "");
    img.setAttribute("alt", el.getAttribute("data-alt") || "");
    img.setAttribute("width", el.getAttribute("data-width") || "");
    img.setAttribute("height", el.getAttribute("data-height") || "");
    el.appendChild(img);

    mediumZoom(".slb", {
      background: "rgba(127,127,127,.8)"
    });
  }
});

const tv = document.querySelector(".tradingview-widget-container");
// @ts-ignore
const tvObserver = lozad(tv, {
  threshold: 0.1,
  load() {
    TVScript();
  }
});

observer.observe();
pictureTagObserver.observe();
bgImageObserver.observe();
tvObserver.observe();
