
import { Vue, Prop, Component } from "vue-property-decorator";
import VueScrollTo from "vue-scrollto";
@Component
export default class extends Vue {
  @Prop(String) readonly defaultHash?: string;
  @Prop(Array) readonly hashes!: string[];
  @Prop({ default: -150 }) readonly offset?: number;
  @Prop(String) readonly target?: string;
  currentHash = "";

  mounted() {
    this.currentHash = this.defaultHash || this.hashes[0];
    window.addEventListener("hashchange", this.hashHandler, false);

    let hash = window.location.hash;
    if (!hash) {
      return;
    }

    this.hashHandler();
  }

  destroyed() {
    window.removeEventListener("hashchange", this.hashHandler, false);
  }

  hashHandler() {
    const isHashFound = this.hashes.find(hash => {
      return (
        hash.toLowerCase() === location.hash.replace("#", "").toLowerCase()
      );
    });

    if (isHashFound) {
      this.currentHash = isHashFound;
    }
  }

  setHash() {
    location.hash = this.currentHash;
  }

  prevSlide() {
    const index = this.hashes.indexOf(this.currentHash);
    this.currentHash = this.hashes[index - 1];
    this.setHash();
    this.scrollTo();
  }

  nextSlide() {
    const index = this.hashes.indexOf(this.currentHash);
    this.currentHash = this.hashes[index + 1];
    this.setHash();
    this.scrollTo();
  }

  scrollTo() {
    const options = {
      container: "body",
      easing: "ease-in",
      offset: this.offset,
      force: true,
      cancelable: false,
      x: false,
      y: true
    };
    if (this.target) {
      VueScrollTo.scrollTo(this.target, 500, options);
    }
  }
}
