
import { Vue, Prop, Component } from "vue-property-decorator";
import VueScrollTo from "vue-scrollto";
import AdmIconButton from "@afe/admirals-dev/src/components/AdmIconButton";
import throttle from "lodash/throttle";

@Component({
  components: { AdmIconButton }
})
export default class extends Vue {
  @Prop(String) readonly target!: string;
  @Prop({ default: "arrow_forward_24px" }) readonly icon!: string;

  checkScroll = throttle(this.scrollHandler, 60);

  mounted() {
    window.addEventListener("scroll", this.checkScroll, false);
  }

  destroyed() {
    window.removeEventListener("scroll", this.checkScroll, false);
  }

  scrollHandler() {
    const buttonWrap = this.$refs.buttonWrap as HTMLElement;
    const offsetTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;

    offsetTop > 600
      ? buttonWrap.classList.add("show-btn")
      : buttonWrap.classList.remove("show-btn");
  }

  onClick() {
    const options = {
      container: "body",
      easing: "ease-in",
      force: true,
      cancelable: false,
      x: false,
      y: true
    };
    if (this.target) {
      VueScrollTo.scrollTo(this.target, 500, options);
    }
  }
}
