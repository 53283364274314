
import { Component, Prop, Vue } from "vue-property-decorator";
import FacebookIcon from "@/components/Article/FacebookIcon.vue";
import LinkedinIcon from "@/components/Article/LinkedinIcon.vue";
import TwitterIcon from "@/components/Article/TwitterIcon.vue";

@Component({
  components: {
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon
  }
})
export default class extends Vue {
  @Prop(String) readonly url!: string;
  @Prop(String) readonly title!: string;

  get facebookShare() {
    return `https://www.facebook.com/sharer/sharer.php?u=${this.url}`;
  }

  get linkedinShare() {
    return `https://www.linkedin.com/shareArticle?mini=true&url=${
      this.url
    }&source=AdmiralMarkets&title=${encodeURI(this.title)}`;
  }

  get twitterShare() {
    return `https://twitter.com/intent/tweet?url=${this.url}&text=${encodeURI(
      this.title
    )}`;
  }

  openShareWindow(url: string) {
    window.open(url, "name", "height=500,width=500");
  }
}
