
import { Vue, Prop, Component } from "vue-property-decorator";
import { AmButton } from "@afe/amui/src/components/Button";
import VueScrollTo from "vue-scrollto";
@Component({
  components: { AmButton }
})
export default class extends Vue {
  @Prop(String) readonly target!: string;
  @Prop({ default: -150 }) readonly offset!: number;

  onClick() {
    const options = {
      container: "body",
      easing: "ease-in",
      offset: this.offset,
      force: true,
      cancelable: false,
      lazy: false,
      x: false,
      y: true
    };
    if (this.target) {
      VueScrollTo.scrollTo(this.target, 500, options);
    }
  }
}
