
import { Vue, Prop, Component } from "vue-property-decorator";
@Component({
  components: {}
})
export default class extends Vue {
  @Prop(String) readonly data!: string;
  headerData: any = {};
  rowsData: any = {};
  beforeMount() {
    const tableData = JSON.parse(this.data);
    this.headerData = tableData.headerData;
    this.rowsData = tableData.rowsData;
  }
}
