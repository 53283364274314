
import { Component, Prop, Vue } from "vue-property-decorator";
import AdmButton from "@afe/admirals-dev/src/components/AdmButton/AdmButton.vue";
import Cookie from "js-cookie";
import throttle from "lodash/throttle";

@Component({
  components: { AdmButton }
})
export default class extends Vue {
  @Prop(String) readonly id!: string;
  scroll = false;

  cookie = {
    old: "680d2df53f927910571711b543a45407",
    new: "270f24491169eb28efa469398a420e82"
  };

  showSwitchButton = !!(
    Cookie.get("home_page_version") === this.cookie.new ||
    Cookie.get("switch_home_page_version")
  );

  cookieSwitch(value: string) {
    Cookie.set("home_page_version", value, {
      expires: 365
    });
  }

  onClick() {
    Cookie.set("switch_home_page_version", "1", {
      expires: 365
    });

    if (Cookie.get("home_page_version") === this.cookie.old) {
      this.cookieSwitch(this.cookie.new);
    } else {
      this.cookieSwitch(this.cookie.old);
    }

    if ("scrollRestoration" in history) {
      history.scrollRestoration = "manual";
    }

    window.location.reload(true);
  }

  mounted() {
    if (this.showSwitchButton) {
      window.addEventListener(
        "scroll",
        throttle(() => {
          const offsetTop =
            (document.documentElement && document.documentElement.scrollTop) ||
            document.body.scrollTop;

          this.scroll = offsetTop > 600;
        }, 60),
        false
      );
    }
  }
}
