
import { Vue, Prop, Component } from "vue-property-decorator";
import { HeaderTitle, Quote, QuotesConfig, Tab } from "@/api/quotes";
import QuotesList from "./QuotesList.vue";
import QuotesFooter from "./QuotesFooter.vue";
import QuotesHeader from "./QuotesHeader.vue";
import QuotesTab from "./QuotesTab.vue";
import { api } from "@/forms/api";

declare const vueQuotesConfig: QuotesConfig;

@Component({
  components: {
    QuotesTab,
    QuotesHeader,
    QuotesFooter,
    QuotesList
  }
})
export default class extends Vue {
  @Prop({ default: () => vueQuotesConfig })
  readonly config!: QuotesConfig;
  @Prop({
    default: () => {
      return (
        vueQuotesConfig.headerTitles || [
          { name: "symbol", label: "Symbol" },
          { name: "bid", label: "Bid" },
          { name: "ask", label: "Ask" },
          { name: "spread", label: "Spread" }
        ]
      );
    }
  })
  readonly headerArray!: Array<HeaderTitle>;

  collection: { [key: string]: any } = {};
  token: string | undefined;
  websocket: WebSocket | undefined;
  pairs: string = this.config.pairs;
  activeTabIndex: number = this.config.defaultActiveTabIndex || 0;
  typicalSpreads: { [key: string]: number } = {};
  isAlive = false;
  pingPongTimerId: any = 0;

  created() {
    this.getTypicalSpreads();
    this.initDummyValues();
    this.updateAccessToken().then(() => {
      this.connectToApiToGetData();
    });
  }

  get headerTitles() {
    return this.headerArray.filter(
      ({ name }) => !(name === "spread" && !this.config.showSellBtn)
    );
  }

  pingPong() {
    if (!this.websocket || this.websocket.readyState !== WebSocket.OPEN) {
      return;
    }

    this.isAlive = false;
    this.websocket.send(JSON.stringify({ ping: 1 }));

    setTimeout(() => {
      if (!this.isAlive && this.websocket) {
        this.websocket.close();
      }
    }, 2000);
  }

  updateAccessToken(): Promise<any> {
    return fetch(this.config.tokenUrl)
      .then(response => response.json())
      .then(({ token }) => {
        if (token) {
          this.token = token;
          setTimeout(() => this.updateAccessToken(), 300000);
        } else {
          throw new Error("Token has not been provided");
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log("Error getting token from server");
      });
  }

  connectToApiToGetData() {
    if (this.token) {
      const websocket = new WebSocket(
        "wss://ticks-tr3.admiralmarkets.com/ws/mt/ticks/"
      );

      websocket.onopen = () => {
        this.pingPong();
        clearInterval(this.pingPongTimerId);
        this.pingPongTimerId = setInterval(this.pingPong, 10000);

        websocket.send(
          JSON.stringify({
            token: this.token,
            instruments: this.pairs
          })
        );
      };

      websocket.onmessage = (event: MessageEvent) => {
        const json = JSON.parse(event.data);

        if (json.data) {
          this.updateQuotes(json.data);
          return;
        }

        if (json.message && json.message === "pong") {
          this.isAlive = true;
          return;
        }

        websocket.close();
      };

      websocket.onclose = () => {
        setTimeout(() => {
          this.connectToApiToGetData();
        }, 1000);
      };

      websocket.onerror = () => {
        setTimeout(() => {
          websocket.close();
        }, 1000);
      };
      this.websocket = websocket;
    }
  }

  updateQuotes(quotes: { [key: string]: Quote }) {
    const currentTime = Math.floor(Date.now() / 1000);
    Object.entries(quotes).forEach(([instrument, quote]: [string, Quote]) => {
      const slug = this.getInstrumentSlug(instrument);
      quote = {
        ...quote,
        slug: slug,
        name: this.getInstrumentName(instrument)
      };

      const fractionDigits = Math.max(
        this.getNumberOfDecimals(this.typicalSpreads[slug] || 0),
        this.getNumberOfDecimals(quote.bid),
        this.getNumberOfDecimals(quote.ask)
      );
      quote.bid = Number(quote.bid).toFixed(fractionDigits);
      quote.ask = Number(quote.ask).toFixed(fractionDigits);

      if (
        this.typicalSpreads.hasOwnProperty(slug) &&
        currentTime - quote.time > 1800 // if last update was more than 30 minutes ago
      ) {
        quote = this.calculateDueToTypicalSpread(
          quote,
          this.typicalSpreads[slug],
          fractionDigits
        );
      } else {
        quote.spread = Number(
          (Number(quote.ask) - Number(quote.bid)).toFixed(fractionDigits)
        );
      }
      quotes = {
        ...quotes,
        [instrument]: quote
      };
    });
    this.collection = quotes;
  }

  getInstrumentName(symbol: string) {
    return this.config.instrumentNames &&
      this.config.instrumentNames.hasOwnProperty(symbol)
      ? this.config.instrumentNames[symbol]
      : symbol;
  }

  getInstrumentSlug(symbol: string) {
    return this.config.instrumentSlugs &&
      this.config.instrumentSlugs.hasOwnProperty(symbol)
      ? this.config.instrumentSlugs[symbol]
      : symbol;
  }

  calculateDueToTypicalSpread(
    quote: Quote,
    spread: number,
    fractionDigits: number
  ) {
    quote.spread = spread;
    quote.ask = (Number(spread) + Number(quote.bid)).toFixed(fractionDigits);

    return quote;
  }

  getTypicalSpreads() {
    return api.quotes
      .getTypicalSpreads(this.getAllPossiblePairsAndSlugs())
      .then(spreads => {
        this.typicalSpreads = spreads.data;
      })
      .catch(() => {});
  }

  initDummyValues() {
    let aPairs = this.config.pairs.split(",");

    let oDummy = {
      bid: "-",
      ask: "-",
      spread: 0.0,
      state: "down",
      time: 1529062366,
      digits: 3
    };

    let collection = this.collection;

    aPairs.forEach(function(pair: string) {
      let pairName = pair.trim().toUpperCase();

      if (pairName) {
        collection[pairName] = oDummy;
      }
    });
  }

  getAllPossiblePairsAndSlugs(): string {
    const slugs = this.config.instrumentSlugs
      ? Object.values(this.config.instrumentSlugs).join(",") + ","
      : "";

    const pairs =
      this.config.tabs && this.config.tabs.length > 0
        ? this.config.tabs.map(({ pairs }) => pairs).join(",")
        : this.pairs;

    return slugs + pairs;
  }

  hasTabs() {
    return this.config.tabs && this.config.tabs.length > 0;
  }

  onTabClick(index: number) {
    if (this.config.tabs) {
      this.pairs = this.config.tabs[index].pairs;
      this.activeTabIndex = index;
      if (this.websocket) {
        this.websocket.close();
      } else {
        this.connectToApiToGetData();
      }
    }
  }

  getNumberOfDecimals(number: number | string) {
    if (Math.floor(Number(number)) == number) {
      return 0;
    }
    return number.toString().split(".")[1].length || 0;
  }
}
