
import { computed, defineComponent, PropType, toRefs } from "vue";
import AdmButton from "@afe/admirals-dev/src/components/AdmButton/AdmButton.vue";
import "./tabs.scss";
import { tabsDirectionType } from "./options";
import scrollIntoView from "scroll-into-view-if-needed";

export default defineComponent({
  name: "adm-tabs",
  components: {
    AdmButton
  },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    id: {
      type: String,
      default: "",
      required: true
    },
    direction: {
      type: String as PropType<tabsDirectionType>,
      default: "horizontal"
    },
    variant: {
      type: String,
      default: "secondary"
    },
    size: {
      type: String,
      default: "large"
    },
    tabs: Array as PropType<
      Array<{
        label: string;
        value: string;
        disabled?: boolean;
        leadingIcon?: string;
        trailingIcon?: string;
        attrs?: object;
      }>
    >,
    value: {
      type: String,
      default: "",
      required: true
    }
  },

  // @ts-ignore
  setup(props, { emit }) {
    const { direction } = toRefs(props);

    const onChange = (value: string) => {
      emit("change", value);
    };

    const classesTabs = computed(() => [
      "adm-tabs",
      `adm-tabs--${direction.value}`
    ]);

    return {
      classesTabs,
      onChange,
      handleClick(event: MouseEvent, index: number) {
        if (event.target) {
          scrollIntoView(event.target as Element, {
            scrollMode: "if-needed",
            block: "nearest",
            inline: "center",
            behavior: "smooth"
          });
        }

        emit("update:modelValue", index);
      }
    };
  }
});
