
import { Vue, Prop, Component } from "vue-property-decorator";
import { QuoteCrypto } from "@/api/quotes";
import CryptoWidgetList from "./CryptoWidgetList.vue";
import "./styles.scss";

@Component({
  components: {
    CryptoWidgetList
  }
})
export default class extends Vue {
  @Prop(Object) readonly config!: any;

  collection: Record<string, QuoteCrypto> = {};
  token: string | undefined;
  websocket: WebSocket | undefined;
  pairs = this.config.pairs.split(",");
  isAlive = false;
  pingPongTimerId: any = 0;

  async created() {
    await this.updateAccessToken();
    this.connectToApiToGetData();
  }

  pingPong() {
    if (!this.websocket || this.websocket.readyState !== WebSocket.OPEN) {
      return;
    }

    this.isAlive = false;
    this.websocket.send(JSON.stringify({ ping: 1 }));

    setTimeout(() => {
      if (!this.isAlive && this.websocket) {
        this.websocket.close();
      }
    }, 2000);
  }

  updateAccessToken(): Promise<any> {
    return fetch(this.config.tokenUrl)
      .then(response => response.json())
      .then(({ token }) => {
        if (token) {
          this.token = token;
          setTimeout(() => this.updateAccessToken(), 300000);
        } else {
          throw new Error("Token has not been provided");
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log("Error getting token from server");
      });
  }

  connectToApiToGetData() {
    if (this.token) {
      const websocket = new WebSocket(
        "wss://ticks-tr3.admiralmarkets.com/ws/mt/ticks-change/"
      );

      websocket.onopen = () => {
        this.pingPong();
        clearInterval(this.pingPongTimerId);
        this.pingPongTimerId = setInterval(this.pingPong, 10000);

        websocket.send(
          JSON.stringify({
            token: this.token,
            instruments: this.config.pairs
          })
        );
      };

      websocket.onmessage = (event: MessageEvent) => {
        const json = JSON.parse(event.data);

        if (json.data) {
          this.updateQuotes(json.data);
          return;
        }

        if (json.message && json.message === "pong") {
          this.isAlive = true;
          return;
        }

        websocket.close();
      };

      websocket.onclose = () => {
        setTimeout(() => {
          this.connectToApiToGetData();
        }, 1000);
      };

      websocket.onerror = () => {
        setTimeout(() => {
          websocket.close();
        }, 1000);
      };
      this.websocket = websocket;
    }
  }

  updateQuotes(quotes: { [key: string]: QuoteCrypto }) {
    const collection: Record<string, QuoteCrypto> = {};
    Object.entries(quotes).forEach(
      ([instrument, quote]: [string, QuoteCrypto]) => {
        const slug = this.getInstrumentSlug(instrument);
        quote = {
          ...quote,
          slug: slug,
          name: this.getInstrumentName(instrument)
        };

        const fractionDigits = Math.max(
          this.getNumberOfDecimals(quote.bid),
          this.getNumberOfDecimals(quote.ask)
        );

        quote.bid = Number(quote.bid).toFixed(fractionDigits);
        quote.ask = Number(quote.ask).toFixed(fractionDigits);

        collection[instrument] = quote;
      }
    );

    this.collection = collection;
  }

  getInstrumentName(symbol: string) {
    return this.config.instrumentNames &&
      this.config.instrumentNames.hasOwnProperty(symbol)
      ? this.config.instrumentNames[symbol]
      : symbol;
  }

  getInstrumentSlug(symbol: string) {
    return this.config.instrumentSlugs &&
      this.config.instrumentSlugs.hasOwnProperty(symbol)
      ? this.config.instrumentSlugs[symbol]
      : symbol;
  }

  getNumberOfDecimals(number: number | string) {
    if (Math.floor(Number(number)) == number) {
      return 0;
    }
    return number.toString().split(".")[1].length || 0;
  }
}
