import axios from "axios";
import get from "lodash/get";
import { createApiForms } from "./forms";
import { createApiTr3 } from "./tr3";
import { createApiUas } from "./uas";
import { createApiQuotes } from "./quotes";

export type ApiConfig = {
  headers?: Record<string, string>;
};

export const createApi = (config: ApiConfig) => {
  if (!config.headers) {
    config.headers = {};
  }

  const instance = axios.create({
    ...config,
    xsrfCookieName: "XSRF-TOKEN",
    withCredentials: true
  });

  instance.interceptors.request.use(config => {
    config.params = {
      ...config.params,
      geoip: new URLSearchParams(window.location.search).get("geoip")
    };
    return config;
  });

  instance.interceptors.response.use(function(response) {
    const profileId =
      get(response, "data.profile_id") || get(response, "data.data.profile_id");
    const clientId =
      get(response, "data.client_id") || get(response, "data.data.client_id");
    const isEmailVerified = get(response, "data.is_email_verified");
    if (profileId && clientId && !isEmailVerified) {
      // @ts-ignore
      const dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "Registration Success",
        client_id: clientId,
        profile_id: profileId
      });
    }

    return response;
  });

  const instanceUas = axios.create({
    ...config,
    xsrfCookieName: "XSRF-TOKEN",
    withCredentials: true
  });
  const instanceTr3 = axios.create({ baseURL: CONFIG.api });
  const instanceQuotes = axios.create(config);
  CONFIG.shared = { instance };

  return {
    forms: createApiForms(instance),
    uas: createApiUas(instanceUas),
    tr3: createApiTr3(instanceTr3),
    quotes: createApiQuotes(instanceQuotes),
    setLocale: (locale: string) => {
      instance.defaults.headers.common["locale"] = locale;
    },
    setBaseUrl: (baseURL: string) => {
      instance.defaults.baseURL = baseURL;
    }
  };
};
