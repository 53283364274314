
import { Component, Prop, Vue } from "vue-property-decorator";
import { AmTabs } from "@afe/amui/src/components/Tabs";

@Component({
  components: {
    AmTabs
  }
})
export default class extends Vue {
  @Prop(Array) readonly tabs!: Array<{
    value: number;
    label: string;
  }>;
  @Prop(Number) readonly selectedTab?: number;
  selected = 0;
  width = window.innerWidth > 0 ? window.innerWidth : screen.width;

  mounted() {
    if (this.width <= 768) {
      this.selected = 1;
      return;
    }

    this.selected = this.selectedTab || this.selected;
  }
}
