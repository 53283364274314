import throttle from "lodash/throttle";

const detector = (el: HTMLElement, elOffsetMiddle: number) => {
  const offsetTop =
    (document.documentElement && document.documentElement.scrollTop) ||
    document.body.scrollTop;

  const bottomOfViewport = offsetTop + window.innerHeight;

  if (bottomOfViewport > elOffsetMiddle) {
    if (el.classList.contains("am-element-detected")) {
      return;
    }

    el.classList.add("am-element-detected");
  }
};

const initDetector = () => {
  const elements: NodeListOf<HTMLElement> | null = document.querySelectorAll(
    ".am-detect-el"
  );

  if (elements.length <= 0) {
    return;
  }

  elements.forEach((el: HTMLElement) => {
    const elOffsetMiddle = el.offsetTop + el.offsetHeight / 2;

    const delectOnScroll = throttle(
      () => detector(<HTMLElement>el, elOffsetMiddle),
      60
    );

    delectOnScroll();

    window.addEventListener("scroll", delectOnScroll);
  });
};

window.addEventListener("DOMContentLoaded", initDetector);
