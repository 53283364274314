
import { Vue, Component } from "vue-property-decorator";
import axios from "axios";

type ChartType = {
  symbol: string;
  bid: number;
  d_c: number;
  chart: { points: string[] };
};
@Component
export default class extends Vue {
  instruments = {
    AAPL: { symbol: "AAPL", title: "Apple" },
    TSLA: { symbol: "TSLA", title: "Tesla" },
    GOOG: { symbol: "GOOG", title: "Google" },
    NFLX: { symbol: "NFLX", title: "Netflix" }
  };

  data = {} as Record<string, ChartType>;

  async mounted() {
    const { data } = await axios.get<Record<string, ChartType>>(
      "/api/quotes/day-changes",
      {
        params: { instruments: Object.keys(this.instruments).join(",") }
      }
    );

    this.data = data;
  }
}
