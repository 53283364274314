import { AxiosInstance } from "axios";

export type QuotesConfig = {
  pairs: string;
  fullPathUrl: string;
  defaultQuoteUrl: string;
  tokenUrl: string;
  showBuyBtn: boolean;
  showSellBtn: boolean;
  translations: Array<Translations>;
  links: Links;
  headerTitles?: Array<HeaderTitle>;
  tabs?: Array<Tab>;
  defaultActiveTabIndex?: number;
  instrumentSlugs?: { [key: string]: string };
  instrumentNames?: { [key: string]: string };
};

export type HeaderTitle = {
  name: string;
  label: string;
};

export type Translations = {
  pricesAboveParagraph: string;
  showAllInstruments: null | string;
  sellButton: string;
  buyButton: string;
};

export type Links = {
  showInstruments: string;
  sellButtonLink: string;
  buyButtonLink: string;
};

export type Quote = {
  time: number;
  symbol: string;
  bid: number | string;
  ask: number | string;
  digits: number;
  bid_direction: number;
  ask_direction: number;
  spread: number | string;
  state: string;
  slug?: string;
  name?: string;
};

export type QuoteCrypto = {
  time: number;
  symbol: string;
  bid: number | string;
  ask: number | string;
  digits: number;
  bid_direction: number;
  ask_direction: number;
  spread: number | string;
  state: string;
  slug?: string;
  name?: string;
  d_c: number;
  w_c: number;
};

export type Tab = {
  name: string;
  pairs: string;
};
export const createApiQuotes = (axios: AxiosInstance) => {
  return {
    getTypicalSpreads: (instruments: string) =>
      axios.get<any, any>(`/api/ajax/quotes_typical_spreads`, {
        params: { instruments: instruments }
      })
  };
};
