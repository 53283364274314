<template>
  <div class="footer-container">
    <span class="quotes-text">{{ translations.pricesAboveParagraph }}</span>

    <span class="show-all-instruments">
      <a
        target="_blank"
        :href="links.showInstruments"
        v-if="translations.showAllInstruments"
      >
        {{ translations.showAllInstruments }}
      </a>
      <a target="_blank" :href="links.showInstruments" hidden v-else>
        {{ links.showInstruments }}
      </a>
    </span>

    <div class="footer-bottom" v-if="hasFooterSlot">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: "quotes-footer",

  props: {
    translations: {
      type: Object,
      required: true
    },

    links: {
      type: Object,
      required: true
    }
  },

  computed: {
    hasFooterSlot() {
      return !!this.$slots.footer;
    }
  }
};
</script>

<style lang="scss" scoped>
.footer-container {
  display: flex;
  align-items: center;
  min-height: 58px;
  flex-wrap: wrap;
  padding: 20px;

  span {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    font-weight: 600;
  }

  .quotes-text {
    font-size: 16px;
    line-height: 26px;
  }

  .footer-bottom span {
    font-size: 12px;
    line-height: 16px;
  }

  .quotes-text,
  .footer-bottom span {
    color: #000000;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal !important;
    text-align: left;
  }

  .show-all-instruments {
    float: right;
    color: #2072e1;
    font-size: 14px;
    line-height: 18px;
    text-align: right;

    @media (max-width: 320px) {
      font-size: 12px;
      text-align: left;
      margin-top: 25px;
    }
  }

  .footer-bottom {
    width: 100%;
  }
}
</style>
