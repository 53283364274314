
import { Vue, Prop, Component } from "vue-property-decorator";
import QuotesRow from "./QuotesRow.vue";
import { Quote, QuotesConfig } from "@/api/quotes";

@Component({
  components: {
    QuotesRow
  }
})
export default class extends Vue {
  @Prop() readonly collection!: Array<Quote>;
  @Prop() readonly config!: QuotesConfig;
}
