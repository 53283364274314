<template>
  <div class="header-container">
    <div class="quote-header" v-for="(item, key) in headerArray" :key="key">
      {{ item.label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headerArray: {
      type: Array,
      required: true
    },

    config: {
      type: Object,
      required: true
    }
  },

  name: "quotes-header",

  computed: {
    showBuySellColumn() {
      if (
        !this.config ||
        typeof this.config.showBuyBtn === "undefined" ||
        typeof this.config.showSellBtn === "undefined"
      ) {
        return false;
      }

      return this.config.showBuyBtn || this.config.showSellBtn;
    }
  }
};
</script>

<style lang="scss" scoped>
.header-container {
  display: flex;

  .quote-header {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;

    color: #9094a1;

    height: 38px;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    margin-top: 20px;

    &:first-child {
      text-align: left !important;
      padding-left: 40px;

      @media (max-width: 425px) {
        padding-left: 5px;
      }
    }
  }

  @media (max-width: 600px) {
    .hide-on-mobile {
      display: none;
    }
  }
}
</style>
