var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-container"},[_vm._t("parallax-bg"),_c('div',{class:_vm.classes.wrapperClass},[_vm._t("default")],2),(
      _vm.$slots['pagination'] ||
        _vm.$slots['button-next'] ||
        _vm.$slots['button-prev'] ||
        _vm.$slots['scrollbar'] ||
        _vm.$slots['extraContent']
    )?_c('div',{staticClass:"swiper-footer"},[_vm._t("pagination"),(_vm.$slots['button-next'] || _vm.$slots['button-prev'])?_c('div',{staticClass:"swiper-buttons"},[_vm._t("button-prev"),_vm._t("button-next")],2):_vm._e(),_vm._t("scrollbar"),_vm._t("extraContent")],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }