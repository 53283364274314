
import { Vue, Prop, Component } from "vue-property-decorator";
import { Quote, Translations } from "@/api/quotes";

@Component
export default class extends Vue {
  @Prop() readonly item!: Quote;
  @Prop() readonly symbol!: string;
  @Prop() readonly fullPathUrl!: string;
  @Prop() readonly regulator!: string;
  @Prop() readonly translations!: Translations;
  @Prop({ default: true }) readonly showBuyBtn!: boolean;
  @Prop({ default: true }) readonly showSellBtn!: boolean;
  @Prop({ default: "" }) readonly buyBtnLink!: string;
  @Prop({ default: "" }) readonly sellBtnLink!: string;

  sanitizeSymbol() {
    return (this.item.slug || this.symbol).toLowerCase();
  }

  getLink() {
    return this.fullPathUrl.replace("{slug}", this.sanitizeSymbol());
  }

  get isAmc() {
    return this.regulator === "iiroc";
  }

  get hasUpState() {
    return this.item.state === "up";
  }

  get hasDownState() {
    return this.item.state === "down";
  }

  get showBuySellColumn() {
    return this.showBuyBtn || this.showSellBtn;
  }
}
