
import axios from "axios";
import { Component, Prop, Vue } from "vue-property-decorator";
import { stickySidebar } from "./sticky";

@Component
export default class extends Vue {
  @Prop(String) readonly id!: string;
  @Prop(String) readonly rating!: string;
  @Prop(String) readonly total!: string;

  totalLikes = 0;
  totalRating = 0;

  mounted() {
    stickySidebar();
    this.setRating(this.rating, this.total);
  }

  setRating(rating: any, likes: any) {
    this.totalRating = Math.round(rating);
    this.totalLikes = parseInt(likes);
    const counter = document.querySelector("#rating-counter");

    if (counter) {
      counter.innerHTML = `${this.totalLikes}`;
    }
  }

  async onRate(value: number) {
    const storage = this.getStorage();

    if (storage[this.id]) {
      return;
    }

    const { data } = await axios.post("/api/ajax/article_rating", {
      id: this.id,
      stars: value
    });

    const { rating } = JSON.parse(data);

    this.setRating(rating, this.totalLikes + 1);
    this.setStorage({ ...storage, [this.id]: { stars: value, isLiked: 1 } });
  }

  getStorage() {
    return JSON.parse(localStorage.getItem("articleRating") || "{}") || {};
  }

  setStorage(data: object) {
    localStorage.setItem("articleRating", JSON.stringify(data));
  }
}
