
import { Vue, Prop, Component } from "vue-property-decorator";
import { AmTypography } from "@afe/amui/src/components/Typography";

@Component({
  components: { AmTypography }
})
export default class extends Vue {
  @Prop() readonly type!: string;

  get classes() {
    return {
      "document-link__pdf": this.type === "pdf",
      "document-link__html": this.type !== "pdf"
    };
  }
}
