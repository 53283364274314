
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class extends Vue {
  @Prop(String) readonly title!: string;
  @Prop(String) readonly img!: string;
  @Prop(String) readonly url!: string;
  @Prop(Boolean) readonly isActive!: boolean;
  @Prop(Boolean) readonly isOpen!: boolean;
}
