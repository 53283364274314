
import { Component, Vue, Prop } from "vue-property-decorator";
import AdmTypography from "@afe/admirals-dev/src/components/AdmTypography";
import AdmButton from "@afe/admirals-dev/src/components/AdmButton/AdmButton.vue";
import AdmSymbol from "@afe/admirals-dev/src/components/AdmSymbol";
import Cookies from "js-cookie";

@Component({
  components: {
    AdmSymbol,
    AdmButton,
    AdmTypography
  }
})
export default class extends Vue {
  @Prop(String) readonly regulatorValue!: string;
  @Prop(String) readonly regulated!: string;
  @Prop(Array) readonly list!: Array<{
    value: string;
    url: string;
    label: string;
  }>;
  opened = false;

  mounted() {
    document.addEventListener("click", ({ target }: Event) => {
      if ((target as Element).classList.contains("regulator-switcher")) {
        return;
      }

      this.opened = false;
    });
  }

  get classes() {
    return {
      "regulator-switcher": true,
      "regulator-switcher--disabled": this.list.length === 1,
      "regulator-switcher--opened": this.opened
    };
  }

  get regulatorLabel() {
    const selected = this.list.find(
      ({ value }) => value === this.regulatorValue
    );

    return selected ? selected.label : "";
  }

  itemClasses(regulator: string) {
    return {
      "regulator-item": true,
      "regulator-item--active": this.regulatorValue === regulator
    };
  }

  setRegulator(regulator: string) {
    Cookies.set("oc_regulator_name", regulator, { expires: 365 * 5 });
    return true;
  }
}
