
import { Component, Prop, Vue } from "vue-property-decorator";
import VueScrollTo from "vue-scrollto";
import { AmTabs } from "@afe/amui/src/components/Tabs";
import throttle from "lodash/throttle";

@Component({
  inheritAttrs: false,
  components: {
    AmTabs
  }
})
export default class extends Vue {
  @Prop(String) readonly tabsClass?: string;
  @Prop({ type: Number, default: 1024 }) breakpoint!: number;
  @Prop(Array) readonly tabs!: Array<{
    value: number;
    target: string;
    label: string;
  }>;
  value = 0;
  offsetBlock = 0;

  modifyHeader(el: HTMLElement, offsetTop: number, offsetEl: number) {
    const header = document.querySelector(".main-menu") as HTMLElement;
    const headerPanel = header.querySelector(".menu-panel") as HTMLElement;
    const headerHeight = header.offsetHeight - headerPanel.offsetHeight;

    el.style.top = headerHeight + "px";

    if (offsetEl <= offsetTop + headerHeight) {
      el.classList.add("fixed");
      headerPanel.setAttribute("hidden", "hidden");
      el.style.zIndex = "99998";
    } else {
      el.classList.remove("fixed");
      headerPanel.removeAttribute("hidden");
    }
  }

  sticky(parent: HTMLElement, el: HTMLElement, offsetEl: number) {
    const breakpointCheck = window.innerWidth > this.breakpoint;

    if (breakpointCheck) {
      const offsetTop =
        (document.documentElement && document.documentElement.scrollTop) ||
        document.body.scrollTop;

      const targets = this.tabs.map(
        ({ target }) =>
          (document.querySelector(target) as HTMLElement).offsetTop
      );
      for (let index = targets.length - 1; index >= 0; index--) {
        if (offsetTop + this.offsetBlock + 10 > targets[index]) {
          this.value = index;
          break;
        }
      }

      parent.removeAttribute("hidden");
      this.modifyHeader(el, offsetTop, offsetEl);
    } else {
      parent.setAttribute("hidden", "hidden");
    }
  }

  mounted() {
    const header = document.querySelector(".main-menu") as HTMLElement;
    const parent = document.querySelector(".tabs-scroll") as HTMLElement;
    const el = parent.querySelector(".tabs-scroll__body") as HTMLElement;
    const offsetEl = el.offsetTop;
    const stickyOnScroll = throttle(
      () => this.sticky(parent, el, offsetEl),
      60
    );

    this.offsetBlock = header.offsetHeight;
    stickyOnScroll();
    window.addEventListener("resize", stickyOnScroll);
    window.addEventListener("scroll", stickyOnScroll);
  }

  onChange(value: number) {
    const target = this.tabs[value].target;

    VueScrollTo.scrollTo(target, 200, {
      container: "body",
      offset: -this.offsetBlock,
      easing: "ease-in",
      lazy: false
    } as any);
  }
}
