
import Tabs from "./Tabs.vue";
import Card from "./Card.vue";
import Accordion from "./Accordion.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    Accordion,
    Tabs,
    Card
  }
})
export default class extends Vue {
  @Prop(Object) readonly config!: any;

  selectedTab = "global";
  tabs = [] as any[];
  data = this.config.methods[this.selectedTab];

  created() {
    this.prepareTabs();
  }

  onChange(tab: string) {
    this.data = this.config.methods[tab];
  }

  prepareTabs() {
    for (const name in this.config.methods) {
      if (this.config.methods.hasOwnProperty(name)) {
        this.tabs.push({
          value: name,
          label: this.config.trans[name]
        });
      }
    }
  }
}
