
import Tabs from "./Tabs.vue";
import Rows from "./Rows.vue";
import { AmIcon } from "@afe/amui/src/components/Icon";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    Rows,
    Tabs,
    AmIcon
  }
})
export default class extends Vue {
  @Prop(Object) readonly config!: any;
  @Prop(Object) readonly trans!: any;

  selected = "";
  items = [] as any[];

  created() {
    this.prepareItems();
  }

  onToggle(value: string) {
    if (this.selected === value) {
      this.selected = "";
    } else {
      this.selected = value;
    }
  }

  @Watch("config")
  onConfigChange() {
    this.prepareItems();
  }

  prepareItems() {
    this.items = [];
    for (const name in this.config) {
      if (this.config.hasOwnProperty(name)) {
        this.items.push({
          value: name,
          label: this.config[name].title,
          image: this.config[name].image
        });
      }
    }
    this.selected = this.items[0].value;
  }
}
