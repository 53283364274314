
import { Component, Prop, Vue } from "vue-property-decorator";
import { AmTabs } from "@afe/amui/src/components/Tabs";

@Component({
  components: {
    AmTabs
  }
})
export default class extends Vue {
  @Prop(Array) readonly tabs!: Array<{
    value: number;
    label: string;
  }>;
  selected = this.tabs[0].value;
}
