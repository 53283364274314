
import { Vue, Prop, Component } from "vue-property-decorator";

@Component({})
export default class extends Vue {
  @Prop(String) readonly selectedItem!: string;
  selected = this.selectedItem;

  handleClick(section: string) {
    this.selected = section;
  }
}
