
import { Vue, Prop, Component } from "vue-property-decorator";
import AdmButton from "@afe/admirals-dev/src/components/AdmButton/AdmButton.vue";
@Component({
  components: { AdmButton }
})
export default class extends Vue {
  @Prop(String) readonly labelMore!: string;
  @Prop(String) readonly labelLess!: string;
  @Prop(String) readonly heightMin!: string;
  @Prop(String) readonly heightMax!: string;
  @Prop(String) readonly id!: string;

  open = false;

  onClick() {
    this.open = !this.open;
  }

  get classes() {
    return [
      this.open ? "read-more-opened" : "read-more-closed",
      "read-more-box"
    ];
  }

  get styles() {
    const maxHeight = this.open ? this.heightMax : this.heightMin;

    return {
      "max-height": `${maxHeight}px`
    };
  }
}
